import { BaseConfiguratorProductStorage } from '../core/storage/index';
import { AlbumConfiguration } from './models';
import Logger from '../core/logger';

export class AlbumStorage extends BaseConfiguratorProductStorage {
  static get productPrefixKey() {
    return 'Album';
  }
}

export async function getAlbumConfiguration(configurationId) {
  let configuration = null;
  if (!configurationId) {
    Logger.warning('getAlbumConfiguration - configurationId must be defined');
    configurationId = await AlbumStorage.getConfigurationPk();
  }

  if (configurationId) {
    configuration = new AlbumConfiguration({ id: configurationId });
    configuration = await configuration.fetch();
  }

  return configuration;
}

export async function getConfigurationOptionalPriceParams(filters, configuration) {
  let params = {};
  if (
    (filters && filters.flyleafTypes && filters.flyleafTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.flyleafType = configuration.flyleafType;
  }
  if (
    (filters &&
      filters.flyleafColorMaterialTypes &&
      filters.flyleafColorMaterialTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.flyleafColorMaterialType = configuration.flyleafColorMaterialType;
  }
  if (
    (filters && filters.bandTypes && filters.bandTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.bandType = configuration.bandType;
  }
  if (
    (filters && filters.coverEngravedTypes && filters.coverEngravedTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.coverEngravedType = configuration.coverEngravedType;
  }
  if (
    (filters && filters.debossingTypes && filters.debossingTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.debossingType = configuration.debossingType;
  }
  if (
    (filters && filters.dryDebossingTypes && filters.dryDebossingTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.dryDebossingType = configuration.dryDebossingType;
  }
  if (
    (filters && filters.gildingTypes && filters.gildingTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.gildingType = configuration.gildingType;
  }
  if (
    (filters && filters.initialTypes && filters.initialTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.initialType = configuration.initialType;
  }
  if (
    (filters && filters.paperLaminationTypes && filters.paperLaminationTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.paperLaminationType = configuration.paperLaminationType;
  }
  if (
    (filters && filters.plaqueTypes && filters.plaqueTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.plaqueType = configuration.plaqueType;
  }
  if (
    (filters && filters.swarovskiTypes && filters.swarovskiTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.swarovskiType = configuration.swarovskiType;
  }
  if (
    (filters && filters.coverUvGraphicTypes && filters.coverUvGraphicTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.coverUvGraphicType = configuration.coverUvGraphicType;
  }
  if (
    (filters && filters.coverUvGraphicThemeTypes && filters.coverUvGraphicThemeTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.coverUvGraphicThemeType = configuration.coverUvGraphicThemeType;
  }
  if (
    (filters &&
      filters.coverUvGraphicElementTypes &&
      filters.coverUvGraphicElementTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.coverUvGraphicElementType = configuration.coverUvGraphicElementType;
  }

  return params;
}

export async function getConfigurationRequiredPriceParams(configuration) {
  return {
    bindingType: configuration.bindingType,
    configurationFamily: configuration.configurationFamily,
    coverColorMaterialType: configuration.coverColorMaterialType,
    orientationType: configuration.orientationType,
    formatType: configuration.formatType,
    paperType: configuration.paperType,
    plateType: configuration.plateType
  };
}
