import { BaseTextWidgetNew } from '../../core/components/baseTextWidgetNew';
import { sprintf } from '../../core/sprintf';

export class InitialsTextWidget extends BaseTextWidgetNew {
  get name() {
    return 'initialTexts';
  }

  get label() {
    return sprintf(
      this.i18n.gettext('testo iniziali in metallo (max %s caratteri)'),
      this.getUserCharLimit()
    );
  }

  async handleChangeText(item, index, event) {
    //need to exclude non-letter digits
    var letters = /^[A-Za-z\s]+$/;
    if (event.target.value.match(letters) || event.target.value === '') {
      event.target.value = event.target.value.toUpperCase();
      return super.handleChangeText(item, index, event);
    }
  }
}
