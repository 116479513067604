import { BaseModel } from '../core/models';
import { conf } from 'outlinejs';

export class AlbumConfiguration extends BaseModel {
  // url

  getAbsoluteUrl() {
    return this.urlRoot + this.id;
  }

  get urlRoot() {
    return conf.settings.ALBUM_CONFIGURATIONS_URL;
  }

  // properties

  get id() {
    return this.get('id');
  }

  get configurationGuid() {
    return this.get('code');
  }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get productType() {
    return this.get('productType');
  }

  set productType(value) {
    this.set('productType', value);
  }

  get productName() {
    return this.get('productName');
  }

  set productName(value) {
    this.set('productName', value);
  }

  get productGuid() {
    return this.get('productGuid');
  }

  set productGuid(value) {
    this.set('productGuid', value);
  }

  get serviceConfigurationTypeCode() {
    return this.get('serviceConfigurationTypeCode');
  }

  set serviceConfigurationTypeCode(value) {
    this.set('serviceConfigurationTypeCode', value);
  }

  get serviceConfigurationTypeName() {
    return this.get('serviceConfigurationTypeName');
  }

  set serviceConfigurationTypeName(value) {
    this.set('serviceConfigurationTypeName', value);
  }

  get coverEditorProjectId() {
    return this.get('coverEditorProjectId');
  }

  set coverEditorProjectId(value) {
    this.set('coverEditorProjectId', value);
  }

  get aeVeloceProjectId() {
    return this.get('aeVeloceProjectId');
  }

  set aeVeloceProjectId(value) {
    this.set('aeVeloceProjectId', value);
  }

  get aeVeloceBlockId() {
    return this.get('aeVeloceBlockId');
  }

  set aeVeloceBlockId(value) {
    this.set('aeVeloceBlockId', value);
  }

  get packagingConfigurationId() {
    return this.get('packagingConfigurationId');
  }

  set packagingConfigurationId(value) {
    this.set('packagingConfigurationId', value);
  }

  get previewImageFileId() {
    return this.get('previewImageFileId');
  }

  set previewImageFileId(value) {
    this.set('previewImageFileId', value);
  }

  get previewStatus() {
    return this.get('previewStatus');
  }

  set previewStatus(value) {
    this.set('previewStatus', value);
  }

  get previewBookCode() {
    return this.get('previewBookCode');
  }

  set previewBookCode(value) {
    this.set('previewBookCode', value);
  }

  get bindingType() {
    return this.get('bindingType');
  }

  set bindingType(value) {
    this.set('bindingType', value);
  }

  get eventType() {
    return this.get('eventType');
  }

  set eventType(value) {
    this.set('eventType', value);
  }

  get pagesNumber() {
    return this.get('pagesNumber');
  }

  set pagesNumber(value) {
    this.set('pagesNumber', value);
  }

  get sheetsNumber() {
    return this.get('pagesNumber') / 2;
  }

  get paperType() {
    return this.get('paperType');
  }

  set paperType(value) {
    this.set('paperType', value);
  }

  get paperLaminationType() {
    return this.get('paperLaminationType');
  }

  set paperLaminationType(value) {
    this.set('paperLaminationType', value);
  }

  get paperFinishingType() {
    return this.get('paperFinishingType');
  }

  set paperFinishingType(value) {
    this.set('paperFinishingType', value);
  }

  get orientationType() {
    return this.get('orientationType');
    //rgrtgtg
  }

  set orientationType(value) {
    this.set('orientationType', value);
  }

  get formatType() {
    return this.get('formatType');
  }

  set formatType(value) {
    this.set('formatType', value);
  }

  get coverColorMaterialType() {
    return this.get('coverColorMaterialType');
  }

  set coverColorMaterialType(value) {
    this.set('coverColorMaterialType', value);
  }

  get metalInitials() {
    return this.get('metalInitials');
  }

  set metalInitials(value) {
    this.set('metalInitials', value);
  }

  get metalInitialsTexts() {
    return this.get('metalInitialsTexts');
  }

  set metalInitialsTexts(value) {
    this.set('metalInitialsTexts', value);
  }

  get engravedMetalBand() {
    return this.get('engravedMetalBand');
  }

  set engravedMetalBand(value) {
    this.set('engravedMetalBand', value);
  }

  get engravedMetalBandTexts() {
    return this.get('engravedMetalBandTexts');
  }

  set engravedMetalBandTexts(value) {
    this.set('engravedMetalBandTexts', value);
  }

  get debossing() {
    return this.get('debossing');
  }

  set debossing(value) {
    this.set('debossing', value);
  }

  get debossingTexts() {
    return this.get('debossingTexts');
  }

  set debossingTexts(value) {
    this.set('debossingTexts', value);
  }

  get dryDebossing() {
    return this.get('dryDebossing');
  }

  set dryDebossing(value) {
    this.set('dryDebossing', value);
  }

  get swarowskiCrystal() {
    return this.get('swarowskiCrystal');
  }

  set swarowskiCrystal(value) {
    this.set('swarowskiCrystal', value);
  }

  get plaqueType() {
    return this.get('plaqueType');
  }

  set plaqueType(value) {
    this.set('plaqueType', value);
  }

  get plaqueTexts() {
    return this.get('plaqueTexts');
  }

  set plaqueTexts(value) {
    this.set('plaqueTexts', value);
  }

  get engravedNamesMetalBar() {
    return this.get('engravedNamesMetalBar');
  }

  set engravedNamesMetalBar(value) {
    this.set('engravedNamesMetalBar', value);
  }

  get engravedNamesMetalBarTexts() {
    return this.get('engravedNamesMetalBarTexts');
  }

  set engravedNamesMetalBarTexts(value) {
    this.set('engravedNamesMetalBarTexts', value);
  }

  get engravedNamesMetalPlaque() {
    return this.get('engravedNamesMetalPlaque');
  }

  set engravedNamesMetalPlaque(value) {
    this.set('engravedNamesMetalPlaque', value);
  }

  get engravedNamesMetalPlaqueTexts() {
    return this.get('engravedNamesMetalPlaqueTexts');
  }

  set engravedNamesMetalPlaqueTexts(value) {
    this.set('engravedNamesMetalPlaqueTexts', value);
  }

  get engravedNamesCover() {
    return this.get('engravedNamesCover');
  }

  set engravedNamesCover(value) {
    this.set('engravedNamesCover', value);
  }

  get engravedNamesCoverTexts() {
    return this.get('engravedNamesCoverTexts');
  }

  set engravedNamesCoverTexts(value) {
    this.set('engravedNamesCoverTexts', value);
  }

  get gildingType() {
    return this.get('gildingType');
  }

  set gildingType(value) {
    this.set('gildingType', value);
  }

  get flyleafType() {
    return this.get('flyleafType');
  }

  set flyleafType(value) {
    this.set('flyleafType', value);
  }

  get flyleafColorMaterialType() {
    return this.get('flyleafColorMaterialType');
  }

  set flyleafColorMaterialType(value) {
    this.set('flyleafColorMaterialType', value);
  }

  get bandType() {
    return this.get('bandType');
  }

  set bandType(value) {
    this.set('bandType', value);
  }

  get bandTexts() {
    return this.get('bandTexts');
  }

  set bandTexts(value) {
    this.set('bandTexts', value);
  }

  get configurationFamily() {
    return this.get('configurationFamily');
  }

  set configurationFamily(value) {
    this.set('configurationFamily', value);
  }

  get coverEngravedType() {
    return this.get('coverEngravedType');
  }

  set coverEngravedType(value) {
    this.set('coverEngravedType', value);
  }

  get coverEngravedTexts() {
    return this.get('coverEngravedTexts');
  }

  set coverEngravedTexts(value) {
    this.set('coverEngravedTexts', value);
  }

  get debossingType() {
    return this.get('debossingType');
  }

  set debossingType(value) {
    this.set('debossingType', value);
  }

  get dryDebossingType() {
    return this.get('dryDebossingType');
  }

  set dryDebossingType(value) {
    this.set('dryDebossingType', value);
  }

  get initialType() {
    return this.get('initialType');
  }

  set initialType(value) {
    this.set('initialType', value);
  }

  get initialTexts() {
    return this.get('initialTexts');
  }

  set initialTexts(value) {
    this.set('initialTexts', value);
  }

  get numberOfPages() {
    return this.get('numberOfPages');
  }

  set numberOfPages(value) {
    this.set('numberOfPages', value);
  }

  get plateType() {
    return this.get('plateType');
  }

  set plateType(value) {
    this.set('plateType', value);
  }

  get plateTexts() {
    return this.get('plateTexts');
  }

  set plateTexts(value) {
    this.set('plateTexts', value);
  }

  get previewImageUrl() {
    return this.get('previewImageUrl');
  }

  set previewImageUrl(value) {
    this.set('previewImageUrl', value);
  }

  get serviceConfigurationName() {
    return this.get('serviceConfigurationName');
  }

  set serviceConfigurationName(value) {
    this.set('serviceConfigurationName', value);
  }

  get swarovskiType() {
    return this.get('swarovskiType');
  }

  set swarovskiType(value) {
    this.set('swarovskiType', value);
  }

  get firstName() {
    return this.get('firstName');
  }

  set firstName(value) {
    this.set('firstName', value);
  }

  get secondName() {
    return this.get('secondName');
  }

  set secondName(value) {
    this.set('secondName', value);
  }

  get eventLocation() {
    return this.get('eventLocation');
  }

  set eventLocation(value) {
    this.set('eventLocation', value);
  }

  get eventStyle() {
    return this.get('eventStyle');
  }

  set eventStyle(value) {
    this.set('eventStyle', value);
  }

  get eventDate() {
    return this.get('eventDate');
  }

  set eventDate(value) {
    this.set('eventDate', new Date(value).toJSON());
  }

  get fullServiceDesignRequested() {
    return this.get('fullServiceDesignRequested');
  }

  set fullServiceDesignRequested(value) {
    this.set('fullServiceDesignRequested', value);
  }

  get fullServiceDesignCompleted() {
    return this.get('fullServiceDesignCompleted');
  }

  set fullServiceDesignCompleted(value) {
    this.set('fullServiceDesignCompleted', value);
  }

  get coverUvGraphicType() {
    return this.get('coverUvGraphicType');
  }

  set coverUvGraphicType(value) {
    this.set('coverUvGraphicType', value);
  }

  get coverUvGraphicThemeType() {
    return this.get('coverUvGraphicThemeType');
  }

  set coverUvGraphicThemeType(value) {
    this.set('coverUvGraphicThemeType', value);
  }

  get coverUvGraphicElementType() {
    return this.get('coverUvGraphicElementType');
  }

  set coverUvGraphicElementType(value) {
    this.set('coverUvGraphicElementType', value);
  }

  get coverUvGraphicElementSvgName() {
    return this.get('coverUvGraphicElementSvgName');
  }

  set coverUvGraphicElementSvgName(value) {
    this.set('coverUvGraphicElementSvgName', value);
  }

  get coverUvGraphicTexts() {
    return this.get('coverUvGraphicTexts');
  }

  set coverUvGraphicTexts(value) {
    this.set('coverUvGraphicTexts', value);
  }

  get colorCorrectionCode() {
    return this.get('colorCorrectionCode');
  }

  set colorCorrectionCode(value) {
    this.set('colorCorrectionCode', value);
  }

  get isOutOfStock() {
    return this.get('isOutOfStock');
  }
}

export class AlbumProduct extends BaseModel {
  static get modelName() {
    return 'product';
  }

  get id() {
    return this.get('code');
  }

  get guid() {
    return this.get('guid');
  }

  get frontAssetType() {
    return this.get('frontAssetType');
  }

  get frontAssetUrl() {
    return this.get('frontAssetUrl');
  }

  get familyName() {
    return this.get('familyName');
  }

  get isOutOfStock() {
    return this.get('isOutOfStock');
  }
}

export class AlbumPrice extends BaseModel {
  static get modelName() {
    return 'price';
  }

  get price() {
    return this.get('price');
  }

  get formattedPrice() {
    return this.get('formattedPrice');
  }

  get currency() {
    return this.get('currency');
  }
}

/**------------------- lookups -------------------**/

export class AlbumBookBindingType extends BaseModel {
  static get modelName() {
    return 'bindingType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get minSpreadsNumber() {
    return this.get('minSpreadsNumber');
  }

  get maxSpreadsNumber() {
    return this.get('maxSpreadsNumber');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumEventType extends BaseModel {
  static get modelName() {
    return 'eventType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumPaperType extends BaseModel {
  static get modelName() {
    return 'paperType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumPaperLaminationType extends BaseModel {
  static get modelName() {
    return 'paperLaminationType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumOrientationType extends BaseModel {
  static get modelName() {
    return 'orientationType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumFormatType extends BaseModel {
  static get modelName() {
    return 'formatType';
  }

  get id() {
    return this.get('code');
  }

  get nameCm() {
    return this.get('nameCm');
  }

  get nameIn() {
    return this.get('nameIn');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumCoverColorMaterialType extends BaseModel {
  static get modelName() {
    return 'coverColorMaterialType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get description() {
    return this.get('description');
  }

  get bookImageUrl() {
    return this.get('bookImageUrl');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get displayType() {
    return this.get('displayType');
  }
}

export class AlbumUvGraphicType extends BaseModel {
  static get modelName() {
    return 'coverUvGraphicType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumUvGraphicThemeType extends BaseModel {
  static get modelName() {
    return 'coverUvGraphicThemeType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumUvGraphicElementType extends BaseModel {
  static get modelName() {
    return 'coverUvGraphicElementType';
  }

  get id() {
    return this.get('code');
  }

  get code() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get svgUrl() {
    return this.get('svgUrl');
  }

  get svgName() {
    return this.get('svgName');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumUvGraphicText extends BaseModel {
  static get modelName() {
    return 'coverUvGraphicTexts';
  }

  get id() {
    return this.get('rowCode');
  }

  get rowCode() {
    return this.get('rowCode');
  }

  get rowNumber() {
    return this.get('rowNumber');
  }

  get name() {
    return this.get('name');
  }

  get minTextLength() {
    return this.get('minTextLength');
  }

  get maxTextLength() {
    return this.get('maxTextLength');
  }

  get fieldType() {
    return this.get('fieldType');
  }

  get contentType() {
    return this.get('contentType');
  }

  get isRequired() {
    return this.get('isRequired');
  }
}

export class AlbumFlyleafType extends BaseModel {
  static get modelName() {
    return 'flyleafType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumFlyleafColorMaterialType extends BaseModel {
  static get modelName() {
    return 'flyleafColorMaterialType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumPlaqueType extends BaseModel {
  static get modelName() {
    return 'plaqueType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumPlaqueText extends BaseModel {
  static get modelName() {
    return 'plaqueTexts';
  }

  get id() {
    return this.get('codeRow1');
  }

  get codeRow1() {
    return this.get('codeRow1');
  }

  get nameRow1() {
    return this.get('nameRow1');
  }

  get charLimitRow1() {
    return this.get('charLimitRow1');
  }

  get isRequiredRow1() {
    return this.get('isRequiredRow1');
  }

  get codeRow2() {
    return this.get('codeRow2');
  }

  get nameRow2() {
    return this.get('nameRow2');
  }

  get charLimitRow2() {
    return this.get('charLimitRow2');
  }

  get isRequiredRow2() {
    return this.get('isRequiredRow2');
  }
}

export class AlbumPlateType extends BaseModel {
  static get modelName() {
    return 'plateType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumPlateText extends BaseModel {
  static get modelName() {
    return 'plateTexts';
  }

  get id() {
    return this.get('codeRow1');
  }

  get codeRow1() {
    return this.get('codeRow1');
  }

  get nameRow1() {
    return this.get('nameRow1');
  }

  get charLimitRow1() {
    return this.get('charLimitRow1');
  }

  get isRequiredRow1() {
    return this.get('isRequiredRow1');
  }

  get codeRow2() {
    return this.get('codeRow2');
  }

  get nameRow2() {
    return this.get('nameRow2');
  }

  get charLimitRow2() {
    return this.get('charLimitRow2');
  }

  get isRequiredRow2() {
    return this.get('isRequiredRow2');
  }
}

export class AlbumInitialType extends BaseModel {
  static get modelName() {
    return 'initialType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumInitialsText extends BaseModel {
  static get modelName() {
    return 'initialTexts';
  }

  get id() {
    return this.get('codeRow1');
  }

  get codeRow1() {
    return this.get('codeRow1');
  }

  get nameRow1() {
    return this.get('nameRow1');
  }

  get charLimitRow1() {
    return this.get('charLimitRow1');
  }

  get isRequiredRow1() {
    return this.get('isRequiredRow1');
  }

  get minTextLength1() {
    return this.get('minTextLength1');
  }

  get maxTextLength1() {
    return this.get('maxTextLength1');
  }
}

export class AlbumBandType extends BaseModel {
  static get modelName() {
    return 'bandType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumBandsText extends BaseModel {
  static get modelName() {
    return 'bandTexts';
  }

  get id() {
    return this.get('codeRow1');
  }

  get codeRow1() {
    return this.get('codeRow1');
  }

  get nameRow1() {
    return this.get('nameRow1');
  }

  get charLimitRow1() {
    return this.get('charLimitRow1');
  }

  get isRequiredRow1() {
    return this.get('isRequiredRow1');
  }
}

export class AlbumDebossingType extends BaseModel {
  static get modelName() {
    return 'debossingType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumDebossingText extends BaseModel {
  static get modelName() {
    return 'debossingTexts';
  }

  get id() {
    return this.get('codeRow1');
  }

  get codeRow1() {
    return this.get('codeRow1');
  }

  get nameRow1() {
    return this.get('nameRow1');
  }

  get charLimitRow1() {
    return this.get('charLimitRow1');
  }

  get isRequiredRow1() {
    return this.get('isRequiredRow1');
  }

  get codeRow2() {
    return this.get('codeRow2');
  }

  get nameRow2() {
    return this.get('nameRow2');
  }

  get charLimitRow2() {
    return this.get('charLimitRow2');
  }

  get isRequiredRow2() {
    return this.get('isRequiredRow2');
  }
}

export class AlbumCoverEngravedType extends BaseModel {
  static get modelName() {
    return 'coverEngravedType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumCoverEngravedTexts extends BaseModel {
  static get modelName() {
    return 'coverEngravedTexts';
  }

  get id() {
    return this.get('codeRow1');
  }

  get codeRow1() {
    return this.get('codeRow1');
  }

  get nameRow1() {
    return this.get('nameRow1');
  }

  get charLimitRow1() {
    return this.get('charLimitRow1');
  }

  get isRequiredRow1() {
    return this.get('isRequiredRow1');
  }

  get codeRow2() {
    return this.get('codeRow2');
  }

  get nameRow2() {
    return this.get('nameRow2');
  }

  get charLimitRow2() {
    return this.get('charLimitRow2');
  }

  get isRequiredRow2() {
    return this.get('isRequiredRow2');
  }
}

export class AlbumDryDebossingType extends BaseModel {
  static get modelName() {
    return 'dryDebossingType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumSwarovskiCrystalType extends BaseModel {
  static get modelName() {
    return 'swarovskiType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumGildingType extends BaseModel {
  static get modelName() {
    return 'gildingType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumCoverPhotoType extends BaseModel {
  static get modelName() {
    return 'coverPhotoType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class AlbumCoverWithPhotoProject extends BaseModel {
  get urlRoot() {
    return conf.settings.COVER_WITH_PHOTO_EDITOR_PROJECT_URL;
  }

  get id() {
    return this.get('id');
  }

  get projectType() {
    return this.get('projectType');
  }

  set projectType(value) {
    this.set('projectType', value);
  }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get language() {
    return this.get('language');
  }

  set language(value) {
    this.set('language', value);
  }

  get productConfigurationId() {
    return this.get('productConfigurationId');
  }

  set productConfigurationId(value) {
    this.set('productConfigurationId', value);
  }

  get productConfigurationType() {
    return this.get('productConfigurationType');
  }

  set productConfigurationType(value) {
    this.set('productConfigurationType', value);
  }

  get coverId() {
    return this.get('coverId');
  }

  set coverId(value) {
    this.set('coverId', value);
  }

  get init() {
    return this.get('init');
  }

  set init(value) {
    this.set('init', value);
  }

  get saved() {
    return this.get('saved');
  }

  set saved(value) {
    this.set('saved', value);
  }
}
