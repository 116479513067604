import { routers } from 'outlinejs';
import { routing } from 'outlinejs';

import {
  AlbumController,
  AlbumCreateController,
  AlbumRestoreProjectController,
  AlbumRestoreAlbumConfigurationController
} from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('album:main', AlbumController),
      'new/': routing.url('album:new', AlbumCreateController),
      new: routing.url('album:new', AlbumCreateController),
      'edit/:projectId:': routing.url('album:restoreProject', AlbumRestoreProjectController),
      'edit-configuration/:albumConfigurationId:': routing.url(
        'album:restoreAlbumConfiguration',
        AlbumRestoreAlbumConfigurationController
      )
    };
  }
}
