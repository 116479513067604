import React from 'react';

import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class PaperTypesWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'paperType';
  }

  get label() {
    return this.i18n.gettext('carta');
  }

  renderItems() {
    let items = [];
    if (this.props.items && this.props.items.map) {
      items = this.props.items.map((item) => {
        let activeClass = item.id === this.props.selected ? 'active' : '';
        let itemClass = `btn-ae text-center ${activeClass}`;
        return (
          <div key={item.id} className={this.itemClasName()}>
            <div
              className={itemClass}
              onClick={this.handleClick.bind(this, item)}
              data-action={`set_${this.props.items.name}_${item.id}`}>
              {item.name}
            </div>
          </div>
        );
      });
    }
    return items;
  }
  /*  --- da rimuovere fino a qui ---  */
}
