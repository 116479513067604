import { LoadableCollection } from '../core/managers';
import { conf } from 'outlinejs';

import {
  AlbumProduct,
  AlbumPrice,
  AlbumBookBindingType,
  AlbumEventType,
  AlbumPaperType,
  AlbumPaperLaminationType,
  AlbumOrientationType,
  AlbumFormatType,
  AlbumCoverColorMaterialType,
  AlbumUvGraphicType,
  AlbumUvGraphicThemeType,
  AlbumUvGraphicElementType,
  AlbumUvGraphicText,
  AlbumFlyleafType,
  AlbumFlyleafColorMaterialType,
  AlbumPlaqueType,
  AlbumPlaqueText,
  AlbumDebossingType,
  AlbumDebossingText,
  AlbumCoverEngravedType,
  AlbumCoverEngravedTexts,
  AlbumDryDebossingType,
  AlbumSwarovskiCrystalType,
  AlbumGildingType,
  AlbumBandType,
  AlbumBandsText,
  AlbumInitialType,
  AlbumInitialsText,
  AlbumPlateType,
  AlbumPlateText,
  AlbumCoverPhotoType
} from './models';

export class AlbumProductCollection extends LoadableCollection {
  get name() {
    return 'albumProducts';
  }

  get url() {
    return conf.settings.ALBUM_PRODUCTS_URL;
  }

  get model() {
    return AlbumProduct;
  }
}

export class AlbumPriceCollection extends LoadableCollection {
  get name() {
    return 'albumPrices';
  }

  get url() {
    return conf.settings.ALBUM_PRICES_URL;
  }

  get model() {
    return AlbumPrice;
  }
}

export class AlbumBindingTypesCollection extends LoadableCollection {
  get name() {
    return 'bindingTypes';
  }

  get url() {
    return conf.settings.ALBUM_BINDING_URL;
  }

  get model() {
    return AlbumBookBindingType;
  }

  get loadParams() {
    return ['configurationFamily', 'configurationId'];
  }
}

export class AlbumEventTypesCollection extends LoadableCollection {
  get name() {
    return 'eventTypes';
  }

  get url() {
    return conf.settings.ALBUM_EVENT_TYPE_URL;
  }

  get model() {
    return AlbumEventType;
  }
}

export class AlbumPaperTypesCollection extends LoadableCollection {
  get name() {
    return 'paperTypes';
  }

  get url() {
    return conf.settings.ALBUM_PAPER_FINISHING_URL;
  }

  get model() {
    return AlbumPaperType;
  }

  get loadParams() {
    return ['configurationFamily', 'bindingType'];
  }
}

export class AlbumPaperLaminationTypesCollection extends LoadableCollection {
  get name() {
    return 'paperLaminationTypes';
  }

  get url() {
    return conf.settings.ALBUM_PAGE_LAMINATION_URL;
  }

  get model() {
    return AlbumPaperLaminationType;
  }

  get loadParams() {
    return ['configurationFamily', 'bindingType', 'paperType'];
  }
}

export class AlbumOrientationTypesCollection extends LoadableCollection {
  get name() {
    return 'orientationTypes';
  }

  get url() {
    return conf.settings.ALBUM_ORIENTATION_URL;
  }

  get model() {
    return AlbumOrientationType;
  }

  get loadParams() {
    return ['configurationFamily', 'bindingType', 'paperType'];
  }
}

export class AlbumFormatTypesCollection extends LoadableCollection {
  get name() {
    return 'formatTypes';
  }

  get url() {
    return conf.settings.ALBUM_FORMAT_URL;
  }

  get model() {
    return AlbumFormatType;
  }

  get loadParams() {
    return [
      'configurationFamily',
      'bindingType',
      'orientationType',
      'paperType',
      'configurationId'
    ];
  }
}

export class AlbumCoverColorMaterialTypesCollection extends LoadableCollection {
  get name() {
    return 'coverColorMaterialTypes';
  }

  get url() {
    return conf.settings.ALBUM_COVER_COLORS_MATERIALS_URL;
  }

  get model() {
    return AlbumCoverColorMaterialType;
  }

  get loadParams() {
    return ['configurationFamily', 'formatType'];
  }
}

export class AlbumUvGraphicCollection extends LoadableCollection {
  get name() {
    return 'coverUvGraphicTypes';
  }

  get url() {
    return conf.settings.ALBUM_COVER_UV_GRAPHIC_URL;
  }

  get model() {
    return AlbumUvGraphicType;
  }

  get loadParams() {
    return ['configurationFamily', 'formatType', 'coverColorMaterialType'];
  }
}

export class AlbumUvGraphicThemeCollection extends LoadableCollection {
  get name() {
    return 'coverUvGraphicThemeTypes';
  }

  get url() {
    return conf.settings.ALBUM_COVER_UV_GRAPHIC_THEME_URL;
  }

  get model() {
    return AlbumUvGraphicThemeType;
  }

  get loadParams() {
    return ['configurationFamily', 'coverUvGraphicType', 'formatType', 'coverColorMaterialType'];
  }
}

export class AlbumUvGraphicElementCollection extends LoadableCollection {
  get name() {
    return 'coverUvGraphicElementTypes';
  }

  get url() {
    return conf.settings.ALBUM_COVER_UV_GRAPHIC_ELEMENT_URL;
  }

  get model() {
    return AlbumUvGraphicElementType;
  }

  get loadParams() {
    return [
      'coverUvGraphicThemeType',
      'configurationFamily',
      'coverUvGraphicType',
      'formatType',
      'coverColorMaterialType'
    ];
  }
}

export class AlbumUvGraphicTextCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'coverUvGraphicTexts';
  }

  get url() {
    return conf.settings.ALBUM_COVER_UV_GRAPHIC_TEXT_URL;
  }

  get model() {
    return AlbumUvGraphicText;
  }

  get loadParams() {
    return [
      'formatType',
      'coverUvGraphicElementType',
      'coverUvGraphicType',
      'coverColorMaterialType',
      'configurationFamily',
      'coverUvGraphicThemeType'
    ];
  }
}

export class AlbumFlyleafTypeCollection extends LoadableCollection {
  get name() {
    return 'flyleafTypes';
  }

  get url() {
    return conf.settings.ALBUM_COVER_FLYLEAF_URL;
  }

  get model() {
    return AlbumFlyleafType;
  }

  get loadParams() {
    return ['configurationFamily', 'bindingType'];
  }
}

export class AlbumFlyleafColorMaterialTypeCollection extends LoadableCollection {
  get name() {
    return 'flyleafColorMaterialTypes';
  }

  get url() {
    return conf.settings.ALBUM_COVER_FLYLEAF_COLORS_MATERIALS_URL;
  }

  get model() {
    return AlbumFlyleafColorMaterialType;
  }

  get loadParams() {
    return ['configurationFamily', 'formatType', 'flyleafType', 'coverColorMaterialType'];
  }
}

export class AlbumPlaqueTypesCollection extends LoadableCollection {
  get name() {
    return 'plaqueTypes';
  }

  get url() {
    return conf.settings.ALBUM_PLAQUE_URL;
  }

  get model() {
    return AlbumPlaqueType;
  }

  get loadParams() {
    return ['configurationFamily', 'formatType'];
  }
}

export class AlbumPlaqueTextCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'plaqueTexts';
  }

  get url() {
    return conf.settings.ALBUM_PLAQUE_TEXT_URL;
  }

  get model() {
    return AlbumPlaqueText;
  }

  get loadParams() {
    return ['configurationFamily', 'plaqueType', 'formatType'];
  }
}

export class AlbumInitialTypesCollection extends LoadableCollection {
  get name() {
    return 'initialTypes';
  }

  get url() {
    return conf.settings.ALBUM_INITIALS_URL;
  }

  get model() {
    return AlbumInitialType;
  }

  get loadParams() {
    return ['configurationFamily', 'formatType'];
  }
}

export class AlbumInitialsTextCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'initialTexts';
  }

  get url() {
    return conf.settings.ALBUM_INITIALS_TEXT_URL;
  }

  get model() {
    return AlbumInitialsText;
  }

  get loadParams() {
    return ['configurationFamily', 'initialType', 'formatType'];
  }
}

export class AlbumBandTypesCollection extends LoadableCollection {
  get name() {
    return 'bandTypes';
  }

  get url() {
    return conf.settings.ALBUM_BANDS_URL;
  }

  get model() {
    return AlbumBandType;
  }

  get loadParams() {
    return ['configurationFamily', 'formatType', 'initialType'];
  }
}

export class AlbumBandsTextCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'bandTexts';
  }

  get url() {
    return conf.settings.ALBUM_BANDS_TEXT_URL;
  }

  get model() {
    return AlbumBandsText;
  }

  get loadParams() {
    return ['configurationFamily', 'bandType', 'formatType', 'initialType'];
  }
}

export class AlbumDebossingTypesCollection extends LoadableCollection {
  get name() {
    return 'debossingTypes';
  }

  get url() {
    return conf.settings.ALBUM_DEBOSSINGS_URL;
  }

  get model() {
    return AlbumDebossingType;
  }

  get loadParams() {
    return ['configurationFamily', 'formatType'];
  }
}

export class AlbumDebossingTextCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'debossingTexts';
  }

  get url() {
    return conf.settings.ALBUM_DEBOSSING_TEXT_URL;
  }

  get model() {
    return AlbumDebossingText;
  }

  get loadParams() {
    return ['configurationFamily', 'debossingType', 'formatType'];
  }
}

export class AlbumCoverEngravedTypesCollection extends LoadableCollection {
  get name() {
    return 'coverEngravedTypes';
  }

  get url() {
    return conf.settings.ALBUM_COVER_ENGRAVEDS_URL;
  }

  get model() {
    return AlbumCoverEngravedType;
  }

  get loadParams() {
    return ['configurationFamily', 'formatType'];
  }
}

export class AlbumCoverEngravedTextsCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'coverEngravedTexts';
  }

  get url() {
    return conf.settings.ALBUM_COVER_ENGRAVEDS_TEXT_URL;
  }

  get model() {
    return AlbumCoverEngravedTexts;
  }

  get loadParams() {
    return ['configurationFamily', 'coverEngravedType', 'formatType'];
  }
}

export class AlbumDryDebossingTypesCollection extends LoadableCollection {
  get name() {
    return 'dryDebossingTypes';
  }

  get url() {
    return conf.settings.ALBUM_DRY_DEBOSSINGS_URL;
  }

  get model() {
    return AlbumDryDebossingType;
  }

  get loadParams() {
    return ['configurationFamily', 'formatType', 'debossingType'];
  }
}

export class AlbumSwarovskiCrystalTypesCollection extends LoadableCollection {
  get name() {
    return 'swarovskiTypes';
  }

  get url() {
    return conf.settings.ALBUM_SWAROVSKY_CRYSTAL_URL;
  }

  get model() {
    return AlbumSwarovskiCrystalType;
  }

  get loadParams() {
    return ['configurationFamily', 'formatType'];
  }
}

export class AlbumGildingTypesCollection extends LoadableCollection {
  get name() {
    return 'gildingTypes';
  }

  get url() {
    return conf.settings.ALBUM_GILDINGS_URL;
  }

  get model() {
    return AlbumGildingType;
  }

  get loadParams() {
    return ['configurationFamily', 'bindingType', 'configurationFamily', 'formatType'];
  }
}

export class AlbumPlateTypesCollection extends LoadableCollection {
  get name() {
    return 'plateTypes';
  }

  get url() {
    return conf.settings.ALBUM_PLATE_URL;
  }

  get model() {
    return AlbumPlateType;
  }

  get loadParams() {
    return ['configurationFamily', 'formatType'];
  }
}

export class AlbumPlateTextCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'plateTexts';
  }

  get url() {
    return conf.settings.ALBUM_PLATE_TEXTS_URL;
  }

  get model() {
    return AlbumPlateText;
  }

  get loadParams() {
    return ['configurationFamily', 'plateType', 'formatType'];
  }
}

export class AlbumCoverPhotoTypesCollection extends LoadableCollection {
  get name() {
    return 'coverPhotoTypes';
  }

  get url() {
    return conf.settings.ALBUM_COVER_PHOTO_URL;
  }

  get model() {
    return AlbumCoverPhotoType;
  }

  get loadParams() {
    return ['configurationFamily', 'formatType'];
  }
}
