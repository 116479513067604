import { conf } from 'outlinejs';
import React from 'react';
import classNames from 'classnames';
import { GraphicCollapsableWidgetWithText } from '../../core/components/graphicCollapsableWidgetWithText';
import { AeImage } from '../../core/components/AeImage';
import { OutOfStockAlert } from '../../core/components/OutOfStockAlert';

export class CoverColorMaterialTypeWidget extends GraphicCollapsableWidgetWithText {
  get name() {
    return 'coverColorMaterialType';
  }

  get label() {
    return this.i18n.gettext('Materiale & Colore:');
  }

  get calculatePrice() {
    return false;
  }

  itemClass(item) {
    return classNames(
      'colors__item',
      {'colors__item_large': item.displayType === 'DOUBLE' }, //eslint-disable-line
      { active: item.id === this.props.selected }
    );
  }

  columnClass(item) {
    return item.displayType === 'DOUBLE'
      ? 'col-xs-12 col-sm-12 col-md-12 padding-color'
      : 'col-xs-6 col-sm-4 col-md-6 padding-color';
  }

  imageClass(item) {
    return item.displayType === 'DOUBLE' ? 'colors__item__image_large' : 'colors__item__image';
  }

  /*render all the avalilable items*/
  renderItems() {
    let renderedItems = [];
    let outOfStock = false;
    let outOfStockMessage = this.i18n.gettext(
      `A causa di un ritardo sulla consegna della materia prima, il materiale {OUT_OF_STOCK_MATERIAL} è momentaneamente esaurito. Ci scusiamo del disagio e prevediamo di riprenderne regolarmente le consegne a partire da mercoledì 20 aprile.`
    );

    if (this.props.items && this.props.items.map) {
      renderedItems = this.props.items.map((item) => {
        if (conf.settings.OUT_OF_STOCK_ALBUM_COVER_MATERIALS.includes(item.id)) {
          outOfStock = true;
          outOfStockMessage = outOfStockMessage.replace(
            '{OUT_OF_STOCK_MATERIAL}',
            `<b>${item.name}</b>`
          );
        }

        return (
          <div
            key={item.id}
            className={this.columnClass(item)}
            onMouseOver={this.handleMouseOver.bind(this, item)}
            onMouseOut={this.handleMouseOut.bind(this)}>
            <a
              onClick={this.handleClick.bind(this, item)}
              className={this.itemClass(item)}
              data-action={`set_${this.props.items.name}_${item.id}`}>
              <span className={this.imageClass(item)} style={{ backgroundColor: item.colorCode }}>
                <AeImage src={item.fileUrl} />
              </span>
              <span className="colors__item__text">
                {item.name}
                <br />
                {item.description}
              </span>
            </a>
          </div>
        );
      });
    }

    return (
      <>
        {outOfStock && <OutOfStockAlert msg={outOfStockMessage} />}
        {renderedItems}
      </>
    );
  }

  /*render only the selected item if available*/
  renderSelectedItem() {
    let renderedItems = [];
    let outOfStock = false;
    let outOfStockMessage = this.i18n.gettext(
      `A causa di un ritardo sulla consegna della materia prima, il materiale {OUT_OF_STOCK_MATERIAL} è momentaneamente esaurito. Ci scusiamo del disagio e prevediamo di riprenderne regolarmente le consegne a partire da mercoledì 20 aprile.`
    );

    if (this.props.items && this.props.items.map) {
      renderedItems = this.props.items.map((item) => {
        if (item.id === this.props.selected) {
          if (conf.settings.OUT_OF_STOCK_ALBUM_COVER_MATERIALS.includes(item.id)) {
            outOfStock = true;
            outOfStockMessage = outOfStockMessage.replace(
              '{OUT_OF_STOCK_MATERIAL}',
              `<b>${item.name}</b>`
            );
          }

          return (
            <div key={item.id} className="col-xs-6 col-sm-10 col-md-10 padding-color vcenter">
              <a className={this.itemClass(item)}>
                <span className={this.imageClass(item)} style={{ backgroundColor: item.colorCode }}>
                  <AeImage src={item.fileUrl} />
                </span>
                <span className="colors__item__text">
                  {item.name}
                  <br />
                  {item.description}
                </span>
              </a>
            </div>
          );
        }
      });
    }

    return (
      <>
        {outOfStock && <OutOfStockAlert msg={outOfStockMessage} />}
        {renderedItems}
      </>
    );
  }

  handleMouseOver(item) {
    this.props.delegate.setPreviewImage(item.bookImageUrl);
  }

  handleMouseOut() {
    this.props.delegate.setPreviewImage();
  }
}
