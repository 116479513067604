import { BaseSelectionWidgetWithImages } from '../../core/components/baseSelectionWidgetWithImages';

export class DryDebossingTypesWidget extends BaseSelectionWidgetWithImages {
  get name() {
    return 'dryDebossingType';
  }

  get label() {
    return this.i18n.gettext('stampa a secco');
  }
}
