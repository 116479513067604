import React from 'react';
import Logger from '../core/logger';

import { BaseContentView, BaseProductView, BaseFiltersView } from '../core/views';

import { ProjectNameWidget } from '../core/components/projectNameWidget';
import { BindingTypesWidget } from './components/bindingTypesWidget';
import { EventTypesWidget } from './components/eventTypesWidget';
import { PaperTypesWidget } from './components/paperTypesWidget';
import { PaperLaminationTypesWidget } from './components/paperLaminationTypesWidget';
import { OrientationTypesWidget } from './components/orientationTypesWidget';
import { FormatTypesWidget } from './components/formatTypesWidget';
import { CoverColorMaterialTypeWidget } from './components/coverColorMaterialTypeWidget';
import { FlyleafTypesWidget } from './components/flyleafTypesWidget';
import { FlyleafColorMaterialTypesWidget } from './components/flyleafColorMaterialTypesWidget';
import { PlaqueTypesWidget } from './components/plaqueTypesWidget';
import { PlaqueTextWidget } from './components/plaqueTextWidget';
import { InitialsTypesWidget } from './components/initialsTypesWidget';
import { InitialsTextWidget } from './components/initialsTextWidget';
import { BandTypesWidget } from './components/bandTypesWidget';
import { BandsTextWidget } from './components/bandsTextWidget';
import { DebossingTypesWidget } from './components/debossingTypesWidget';
import { DebossingTextWidget } from './components/debossingTextWidget';
import { CoverEngravedTypesWidget } from './components/coverEngravedTypesWidget';
import { CoverEngravedTextsWidget } from './components/coverEngravedTextsWidget';
import { DryDebossingTypesWidget } from './components/dryDebossingTypesWidget';
import { SwarovskiCrystalTypesWidget } from './components/swarovskiCrystalTypesWidget';
import { GildingTypesWidget } from './components/gildingTypesWidget';
import { PlateTypesWidget } from './components/plateTypesWidget';
import { PlateTextWidget } from './components/plateTextWidget';
import { CoverWithPhotoEditorWidget } from './components/coverWithPhotoEditorWidget';
import { AlbumMaterialPreview } from './components/albumMaterialPreview';
import { SheetsNumberWidget } from './components/sheetsNumberWidget';
import { EditCoverWidget } from './components/editCoverWidget';
import { FiltersTitle } from '../core/components/filtersTitle';
import { UvGraphicWidget } from './components/uvGraphicWidget';
import { UvGraphicElementWidget } from './components/uvGraphicElementWidget';
import { UvGraphicTextWidget } from './components/uvGraphicTextWidget';

class ProductView extends BaseProductView {
  priceIsLoading() {
    let priceIsLoading;
    if (this.props.updatePrice) {
      priceIsLoading = false;
    } else {
      priceIsLoading =
        this.props.loadingCollection.albumPrices || this.props.productConfigurationIsLoading;
    }
    return priceIsLoading;
  }

  renderPreview() {
    return (
      <div className="row product-preview album-product-preview">
        <div className="col-xs-12">
          <AlbumMaterialPreview
            product={this.props.product}
            height={this.state.productPreviewHeight}
            width={this.state.productPreviewWidth}
          />
        </div>
      </div>
    );
  }
}

class FiltersView extends BaseFiltersView {
  getFilters() {
    return [
      <FiltersTitle key="FiltersTitle" title={this.i18n.gettext('Personalizza il tuo album')} />,

      <ProjectNameWidget
        key="ProjectNameWidget"
        delegate={this.delegate}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
      />,

      <EventTypesWidget
        key="EventTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.eventTypes}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <BindingTypesWidget
        key="BindingTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.bindingTypes}
        selected={this.props.configuration.bindingType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <SheetsNumberWidget
        key="SheetsNumberWidget"
        delegate={this.delegate}
        items={this.props.filters.sheetsNumber || { min: 0, max: 50 }}
        selected={this.props.configuration.sheetsNumber}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PaperTypesWidget
        key="PaperTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.paperTypes}
        selected={this.props.configuration.paperType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PaperLaminationTypesWidget
        key="PaperLaminationTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.paperLaminationTypes}
        selected={this.props.configuration.paperLaminationType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <OrientationTypesWidget
        key="OrientationTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.orientationTypes}
        selected={this.props.configuration.orientationType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <FormatTypesWidget
        key="FormatTypesWidget"
        delegate={this.delegate}
        unitOfMeasure={this.delegate.unitOfMeasure}
        items={this.props.filters.formatTypes}
        selected={this.props.configuration.formatType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <CoverColorMaterialTypeWidget
        key="CoverColorMaterialTypeWidget"
        delegate={this.delegate}
        items={this.props.filters.coverColorMaterialTypes}
        selected={this.props.configuration.coverColorMaterialType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <UvGraphicWidget
        key="UvGraphicWidget"
        delegate={this.delegate}
        items={this.props.filters.coverUvGraphicTypes}
        selected={this.props.configuration.coverUvGraphicType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <UvGraphicElementWidget
        key="UvGraphicElementWidget"
        delegate={this.delegate}
        items={this.props.filters.coverUvGraphicElementTypes}
        selected={this.props.configuration.coverUvGraphicElementSvgName}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <UvGraphicTextWidget
        key="UvGraphicTextWidget"
        delegate={this.delegate}
        items={this.props.filters.coverUvGraphicTexts}
        value={this.props.configuration.coverUvGraphicTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <FlyleafTypesWidget
        key="FlyleafTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.flyleafTypes}
        selected={this.props.configuration.flyleafType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <FlyleafColorMaterialTypesWidget
        key="FlyleafColorMaterialTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.flyleafColorMaterialTypes}
        selected={this.props.configuration.flyleafColorMaterialType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PlaqueTypesWidget
        key="PlaqueTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.plaqueTypes}
        selected={this.props.configuration.plaqueType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PlaqueTextWidget
        key="PlaqueTextWidget"
        delegate={this.delegate}
        items={this.props.filters.plaqueTexts}
        value={this.props.configuration.plaqueTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <InitialsTypesWidget
        key="InitialsTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.initialTypes}
        selected={this.props.configuration.initialType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <InitialsTextWidget
        key="InitialsTextWidget"
        delegate={this.delegate}
        items={this.props.filters.initialTexts}
        value={this.props.configuration.initialTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <BandTypesWidget
        key="BandTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.bandTypes}
        selected={this.props.configuration.bandType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <BandsTextWidget
        key="BandsTextWidget"
        delegate={this.delegate}
        items={this.props.filters.bandTexts}
        value={this.props.configuration.bandTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <DebossingTypesWidget
        key="DebossingTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.debossingTypes}
        selected={this.props.configuration.debossingType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <DebossingTextWidget
        key="DebossingTextWidget"
        delegate={this.delegate}
        items={this.props.filters.debossingTexts}
        value={this.props.configuration.debossingTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <CoverEngravedTypesWidget
        key="CoverEngravedTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.coverEngravedTypes}
        selected={this.props.configuration.coverEngravedType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <CoverEngravedTextsWidget
        key="CoverEngravedTextsWidget"
        delegate={this.delegate}
        items={this.props.filters.coverEngravedTexts}
        value={this.props.configuration.coverEngravedTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <DryDebossingTypesWidget
        key="DryDebossingTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.dryDebossingTypes}
        selected={this.props.configuration.dryDebossingType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <SwarovskiCrystalTypesWidget
        key="SwarovskiCrystalTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.swarovskiTypes}
        selected={this.props.configuration.swarovskiType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <GildingTypesWidget
        key="GildingTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.gildingTypes}
        selected={this.props.configuration.gildingType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PlateTypesWidget
        key="PlateTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.plateTypes}
        selected={this.props.configuration.plateType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PlateTextWidget
        key="PlateTextWidget"
        delegate={this.delegate}
        items={this.props.filters.plateTexts}
        value={this.props.configuration.plateTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <CoverWithPhotoEditorWidget
        key="CoverWithPhotoEditorWidget"
        delegate={this.delegate}
        items={this.props.filters.coverPhotoTypes}
        showCoverEditor={this.delegate.showCoverEditor}
        coverEditorApplicationUrl={this.delegate.coverEditorUrl}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <EditCoverWidget key="EditCoverWidget" delegate={this.delegate} />
    ];
  }
}

export class AlbumContentView extends BaseContentView {
  render() {
    return super.render(ProductView, FiltersView);
  }

  constructor(props) {
    super(props);
    this.handleIFrameMessage = this.handleIFrameMessage.bind(this);
  }

  componentDidMount() {
    window.addEventListener('message', this.handleIFrameMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleIFrameMessage, false);
  }

  handleIFrameMessage(event) {
    if (event.data.message === 'CloseAlbumCoverEditorProject') {
      Logger.info('CloseAlbumCoverEditorProject', 'received');
      this.delegate.hideCoverEditorIframe();
    }

    if (event.data.message === 'SaveAlbumCoverEditorProject') {
      Logger.info('SaveAlbumCoverEditorProject', 'received');
      this.delegate.saveCoverEditorProject(event.data.data.projectId, event.data.data.coverId);
      this.delegate.hideCoverEditorIframe();
    }
  }
}
