import { conf } from 'outlinejs';

import CoverEditorBaseController from '../core/coverEditorBaseController';

import { AlbumCoverWithPhotoProject } from './models';

/*
 * Class handler for AlbumCoverWithPhotoProject
 * */
export default class extends CoverEditorBaseController {
  constructor(...args) {
    super(...args);

    this.coverEditorProjectClass = AlbumCoverWithPhotoProject;
    this.applicationBaseUrl = conf.settings.ALBUM_COVER_EDITOR_APPLICATION_URL;
  }
}
