import { BaseTextWidget } from '../../core/components/baseTextWidget';
import { sprintf } from '../../core/sprintf';

export class PlaqueTextWidget extends BaseTextWidget {
  get name() {
    return 'plaqueTexts';
  }

  get label() {
    return sprintf(
      this.i18n.gettext('nomi decorazione (max %s caratteri)'),
      this.getUserCharLimit()
    );
  }
}
