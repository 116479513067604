import { GraphicCollapsableWidgetWithText } from '../../core/components/graphicCollapsableWidgetWithText';

export class FlyleafColorMaterialTypesWidget extends GraphicCollapsableWidgetWithText {
  get name() {
    return 'flyleafColorMaterialType';
  }

  get label() {
    return this.i18n.gettext('colore risguardo');
  }
}
