import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class FlyleafTypesWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'flyleafType';
  }

  get label() {
    return this.i18n.gettext('risguardo');
  }
}
