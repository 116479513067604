import { BaseSelectionWidgetWithImages } from '../../core/components/baseSelectionWidgetWithImages';

export class SwarovskiCrystalTypesWidget extends BaseSelectionWidgetWithImages {
  get name() {
    return 'swarovskiType';
  }

  get label() {
    return this.i18n.gettext('swarovski');
  }
}
