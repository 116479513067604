import { BaseSelectionWidgetWithImages } from '../../core/components/baseSelectionWidgetWithImages';

export class GildingTypesWidget extends BaseSelectionWidgetWithImages {
  get name() {
    return 'gildingType';
  }

  get label() {
    return this.i18n.gettext('labbratura');
  }
}
