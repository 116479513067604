import React from 'react';
import classNames from 'classnames';

import BaseBootstrapWidget from '../../core/components/baseBootstrapWidget';

export class EditCoverWidget extends BaseBootstrapWidget {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  get name() {
    return 'editCover';
  }

  get label() {
    return this.i18n.gettext('modifica la copertina');
  }

  async handleClick() {
    this.props.delegate.goToCatalogue();
  }

  visibleCLass() {
    return classNames('block__widget', { disabled: this.state.disabled });
  }

  get loadingClass() {
    return this.loadingClassActive && this.loading ? `${this._loadingClass}` : null;
  }

  render() {
    return (
      <div
        className={`${this.visibleCLass()} ${this.loadingClass}`}
        ref={(div) => {
          this.widgetElement = div;
        }}>
        {this.renderLabel()}
        <div id={this.name}>
          <div className="product__formats" data-product-formats="">
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6 no-padding-col">
                <div
                  className="btn-ae text-center"
                  onClick={this.handleClick}
                  data-action="edit_cover_button">
                  {this.i18n.gettext('Modifica copertina')}{' '}
                </div>
              </div>
            </div>
            {this.renderErrors()}
          </div>
        </div>
      </div>
    );
  }
}
