import { BaseSelectionWidgetWithImages } from '../../core/components/baseSelectionWidgetWithImages';

export class BandTypesWidget extends BaseSelectionWidgetWithImages {
  get name() {
    return 'bandType';
  }

  get label() {
    return this.i18n.gettext('Fascia di metallo con incisione');
  }
}
