import { BaseSelectionWidgetWithImages } from '../../core/components/baseSelectionWidgetWithImages';

export class InitialsTypesWidget extends BaseSelectionWidgetWithImages {
  get name() {
    return 'initialType';
  }

  get label() {
    return this.i18n.gettext('iniziali in metallo');
  }
}
