import { BaseTextWidget } from '../../core/components/baseTextWidget';
import { sprintf } from '../../core/sprintf';

export class DebossingTextWidget extends BaseTextWidget {
  get name() {
    return 'debossingTexts';
  }

  get label() {
    return sprintf(
      this.i18n.gettext('nomi decorazione (max %s caratteri)'),
      this.getUserCharLimit()
    );
  }
}
