import { runtime } from '@outlinejs/contexts';
import { conf } from 'outlinejs';
import Cookies from 'js-cookie';
import { navigateTo } from '../core/utils/index';
import { AlbumProductCollection } from './managers';
import { AlbumConfiguration } from './models';
import { ReferralStorage } from '../core/storage/index';
import { cleanAlbumConfigurator } from '../core/storage/cleaners';
import Logger from '../core/logger';

export default class {
  async processRequest(request, response) {
    if (runtime.isClient) {
      let url = new URL(request.absoluteUrl);
      let newAlbumState = url.pathname;

      if (newAlbumState && newAlbumState.indexOf('album/new') !== -1) {
        let language = this.getLanguage(request);
        let catalogueUrl = await ReferralStorage.getAlbumCatalogueUrl(language);

        if (Object.keys(request.query).length !== 0 && request.query.createConfiguration) {
          await this.cleanConfigurationPk();
          if (request.query.createConfiguration) {
            let albumConfiguration = await this.createAlbumConfiguration(request);

            //redirect to configurator
            if (albumConfiguration) {
              navigateTo(
                request,
                response,
                'album:restoreAlbumConfiguration',
                { albumConfigurationId: albumConfiguration.id },
                true
              );
              return false;
            } else {
              Logger.error('album.middleware.processRequest - create album configuration', {
                request: request,
                albumConfiguration: albumConfiguration
              });
              navigateTo(request, response, catalogueUrl, {}, true);
              return false;
            }
          }
        } else {
          await this.cleanConfigurationPk();

          let queryParams = await this.getQueryParams(request);

          //redirect to catalogue
          navigateTo(this.request, this.response, catalogueUrl, queryParams, true);
        }
      }
    }
  }

  async cleanConfigurationPk() {
    try {
      // clean local forage data
      await cleanAlbumConfigurator();
    } catch (err) {
      Logger.error('Album middleware:processRequest', {
        error: err
      });
    }
  }

  getLanguage(request) {
    let language = 'en';
    if (request && request.language) {
      language = request.language;
    }

    if (request.query.language) {
      language = request.query.language;
    }
    return language;
  }

  async getQueryParams(request) {
    // Set Cookie to reset filters
    try {
      let expireTime = new Date();
      let time = expireTime.getTime();
      time += 60 * 1000;
      expireTime.setTime(time);

      Cookies.set(conf.settings.RESET_FILTERS_COOKIE_KEY, 'true', {
        expires: expireTime,
        domain: conf.settings.RESET_FILTERS_COOKIE_DOMAIN
      });
    } catch (err) {
      console.error(err);
    }

    // remove unnecessary query string params
    let queryParams = request.query;
    let queryParamsToRemove = ['language', 'referralBaseUrl'];
    queryParamsToRemove.forEach((queryParam) => {
      if (queryParams[queryParam]) {
        delete queryParams[queryParam];
      }
    });

    /* PATCH to redirect to new album catalogue */
    let redirectToNewAlbumCollection = true;

    if (queryParams) {
      // convert query param to lower case
      let queryParamsLowercase = [];
      Object.keys(queryParams).forEach((queryParam) => {
        queryParamsLowercase.push(queryParam.toLowerCase());
      });

      // check if an existing parameter filter is available
      let availableQueryParams = [
        'womanStyle',
        'buildType',
        'materialCategory',
        'tagging',
        'collection',
        'cover_collection'
      ];
      availableQueryParams.forEach((availableQueryParam) => {
        if (queryParamsLowercase.indexOf(availableQueryParam.toLowerCase()) > -1) {
          redirectToNewAlbumCollection = false;
        }
      });

      if (!queryParams.length) {
        redirectToNewAlbumCollection = false;
      }
    }

    if (redirectToNewAlbumCollection) {
      queryParams.collection = 'COVER_COLLECTION_2018'; //eslint-disable-line
    }
    /* end PATCH */
    return queryParams;
  }

  async createAlbumConfiguration(request) {
    let albumConfiguration = null;
    let productParams = this.getProductParams(request.query);

    //Get product list with requested parameters
    let product = await this.getProduct(new AlbumProductCollection(), productParams);

    if (product.guid === null || product.guid === undefined) {
      Logger.error('album.middleware.createAlbumConfiguration - product.guid null', {
        product: product,
        productParams: productParams,
        request: request
      });
    }

    //Create new Album
    if (product) {
      try {
        albumConfiguration = new AlbumConfiguration();
        albumConfiguration.productGuid = product.guid;
        albumConfiguration = await albumConfiguration.save();
      } catch (err) {
        Logger.error('album.middleware.createAlbumConfiguration', {
          error: err,
          productGuid: product.attributes.guid
        });
      }
    }
    return albumConfiguration;
  }

  async getProduct(collection, params) {
    let items = null;
    let productItem = null;
    try {
      items = await collection.loadByParams(params);
      productItem = items.first();
    } catch (err) {
      Logger.error('Album middleware:getProduct', {
        error: err,
        params: params,
        items: items
      });
    }
    return productItem;
  }

  getProductParams(queryParams) {
    let productParams = {};
    for (var key in queryParams) {
      if (key !== 'newConfiguration' && key !== 'createConfiguration') {
        productParams[key] = queryParams[key];
      }
    }
    return productParams;
  }
}
