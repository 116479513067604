import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';
import { routing } from 'outlinejs';
//import Cookies from 'js-cookie';

import {
  GuiErrors,
  GuiNotifications,
  navigateTo,
  mergeQueryParams,
  promiseRetry
} from '../core/utils/index';
import Logger from '../core/logger';
import { LayoutServicesCodes } from '../services/utils';
import { BaseConfiguratorController } from '../core/controllers';
import { albumFunnelSteps } from '../core/utils/breadcrumb';
import { ProfessionalPreOrdersCollection } from '../core/managers';
import { cleanAlbumConfigurator } from '../core/storage/cleaners';
import { AlbumProjectStorage } from '../core/storage/index';
import { Project } from '../projects/models';
import { BaseLoadingView } from '../core/views';
import { AlbumContentView } from './views';
import {
  AlbumProductCollection,
  AlbumPriceCollection,
  AlbumBindingTypesCollection,
  AlbumEventTypesCollection,
  AlbumPaperTypesCollection,
  AlbumPaperLaminationTypesCollection,
  AlbumOrientationTypesCollection,
  AlbumFormatTypesCollection,
  AlbumCoverColorMaterialTypesCollection,
  AlbumUvGraphicCollection,
  AlbumUvGraphicThemeCollection,
  AlbumUvGraphicElementCollection,
  AlbumUvGraphicTextCollection,
  AlbumFlyleafTypeCollection,
  AlbumFlyleafColorMaterialTypeCollection,
  AlbumPlaqueTypesCollection,
  AlbumPlaqueTextCollection,
  AlbumInitialTypesCollection,
  AlbumInitialsTextCollection,
  AlbumBandTypesCollection,
  AlbumBandsTextCollection,
  AlbumDebossingTypesCollection,
  AlbumDebossingTextCollection,
  AlbumCoverEngravedTypesCollection,
  AlbumCoverEngravedTextsCollection,
  AlbumDryDebossingTypesCollection,
  AlbumSwarovskiCrystalTypesCollection,
  AlbumGildingTypesCollection,
  AlbumPlateTypesCollection,
  AlbumPlateTextCollection,
  AlbumCoverPhotoTypesCollection
} from './managers';
import { AlbumConfiguration } from './models';
import {
  AlbumStorage,
  getConfigurationOptionalPriceParams,
  getConfigurationRequiredPriceParams
} from './utils';
import { AlbumPackagingStorage } from '../album-packaging/utils';
import AlbumCoverWithPhotoEditor from './coverWithPhotoEditor';
import { ReferralStorage } from '../core/storage/index';
import axios from 'axios';
import EventTracker, { productInterface } from '../core/eventTracker';

/*
 * Controller used to initialize a new album configuration chosen from catalogue
 * Move all logic to the middleware
 * */
export class AlbumCreateController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get view() {
    return BaseLoadingView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering
    });
  }

  async init() {
    // It's not necessary
    // await super.initContentProps();

    this.startInitialRendering();
  }
}

/*
 * Controller used to initialize a new album configuration chosen from catalogue
 * */
export class AlbumRestoreAlbumConfigurationController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get view() {
    return BaseLoadingView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering
    });
  }

  async redirectToAlbumCatalogue() {
    await cleanAlbumConfigurator();
    let url = ReferralStorage.getAlbumCatalogueUrl(this.getLanguage());
    navigateTo(this.request, this.response, url, {}, true);
  }

  async redirectToLoginPage() {
    await cleanAlbumConfigurator();
    let nextUrlEncoded = encodeURIComponent(this.request.absoluteUrl);
    let loginUrl = routing.Utils.reverse('login');
    let loginUrlWithNextUrl = `${loginUrl}?next-url=${nextUrlEncoded}`;
    navigateTo(this.request, this.response, loginUrlWithNextUrl, {}, true);
  }

  async init(albumConfigurationId) {
    this.startInitialRendering();

    await super.initContentProps();

    if (runtime.isClient) {
      let catalogueUrl = await ReferralStorage.getAlbumCatalogueUrl(this.getLanguage());

      if (!albumConfigurationId) {
        Logger.error('AlbumRestoreAlbumConfigurationController.init', {
          error: 'Restored Album Configuration without an id'
        });

        navigateTo(this.request, this.response, catalogueUrl, {}, true);
        return false;
      }

      let configuration = new AlbumConfiguration({ id: albumConfigurationId });

      try {
        await configuration.fetch();
      } catch (err) {
        Logger.error(
          'AlbumRestoreAlbumConfigurationController.init - unable to fetch configuration',
          {
            error: err,
            id: albumConfigurationId
          }
        );
        navigateTo(this.request, this.response, catalogueUrl, {}, true);
        return false;
      }

      // clean local forage data
      await cleanAlbumConfigurator(true);
      let queryParams = null;

      if (!configuration.shopCode) {
        //tutto ok vado avanti
        await AlbumStorage.setConfigurationPk(albumConfigurationId);
        queryParams = { configurationId: albumConfigurationId };
        if (configuration.packagingConfigurationId) {
          queryParams.packagingConfigurationId = configuration.packagingConfigurationId;
          await AlbumPackagingStorage.setConfigurationPk(configuration.packagingConfigurationId);
        }
        // navigateTo(this.request, this.response, 'album:main', {}, true);
        navigateTo(
          this.request,
          this.response,
          'album:main',
          {},
          true,
          mergeQueryParams(this.request.query, queryParams)
        );
        return false;
      } else if (
        this.customerUserIsAuthorized &&
        this.request.customerUser.shopCode === configuration.shopCode
      ) {
        // è mio ed è salvato nel carrello vado in edit
        let preOrders = new ProfessionalPreOrdersCollection();
        await preOrders.filterByConfigurationIdAndProductType(albumConfigurationId, 'Album');
        if (preOrders.first() && preOrders.first().projectId) {
          // todo navigate refactoring not necessary
          navigateTo(
            this.request,
            this.response,
            'album:restoreProject',
            { projectId: preOrders.first().projectId },
            true
          );
          return false;
        } else {
          await AlbumStorage.setConfigurationPk(albumConfigurationId);
          queryParams = { configurationId: albumConfigurationId };
          if (configuration.packagingConfigurationId) {
            await AlbumPackagingStorage.setConfigurationPk(configuration.packagingConfigurationId);
            queryParams.packagingConfigurationId = configuration.packagingConfigurationId;
          }
          // navigateTo(this.request, this.response, 'album:main', {}, true);
          navigateTo(
            this.request,
            this.response,
            'album:main',
            {},
            true,
            mergeQueryParams(this.request.query, queryParams)
          );
          return false;
        }
      } else if (
        this.customerUserIsAuthorized &&
        this.request.customerUser.shopCode !== configuration.shopCode
      ) {
        // sono loggato e lo shop code non è diverso dal mio
        GuiErrors.modalError(
          this.i18n.gettext(
            'Non è possibile ripristinare questo album perché appartiene ad un altro utente.'
          ),
          null,
          null,
          null,
          this.redirectToAlbumCatalogue.bind(this)
        );

        this.stopInitialRendering();
        this.render(this.context);
      } else {
        // non sono loggato
        GuiErrors.modalError(
          this.i18n.gettext('Per ripristinare questo album è necessario loggarsi'),
          null,
          this.i18n.gettext('Login'),
          null,
          this.redirectToLoginPage.bind(this)
        );

        this.stopInitialRendering();
        this.render(this.context);
      }
    }
  }
}

/*
 * Controller used to restore a saved album configuration
 * */
export class AlbumRestoreProjectController extends BaseConfiguratorController {
  get view() {
    return BaseLoadingView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering
    });
  }

  async initClientStorage(project, preOrders) {
    let configurationId = null;
    let packagingConfigurationId = null;
    let packagingPreorderId = null;

    preOrders.forEach((preOrder) => {
      if (preOrder.productType === 'Album') {
        configurationId = preOrder.configurationId;
      }
      if (preOrder.productType === 'AlbumPackaging') {
        packagingConfigurationId = preOrder.configurationId;
        packagingPreorderId = preOrder.id;
      }
    });

    // clean configurator data
    await cleanAlbumConfigurator();

    // set new configurator data
    return await Promise.all([
      AlbumProjectStorage.setPk(project.id),
      AlbumProjectStorage.setName(project.name),
      AlbumStorage.setConfigurationPk(configurationId),
      AlbumPackagingStorage.setConfigurationPk(packagingConfigurationId),
      AlbumPackagingStorage.setPreOrderPk(packagingPreorderId)
    ]);
  }

  async getProject(projectId) {
    return await new Project({ id: projectId }).fetch();
  }

  // async getPreOrders(projectId) {
  //   return await new ProfessionalPreOrdersCollection().filterByProjectId(projectId);
  // }
  async getPreOrders(projectId) {
    let preOrders = await new ProfessionalPreOrdersCollection().filterByProjectId(projectId);

    let albumPreOrder = null;
    let albumPackagingPreOrder = null;
    preOrders.forEach((preOrder) => {
      if (preOrder.productType === conf.settings.ALBUM_PRODUCT_TYPE) {
        albumPreOrder = preOrder;
      }

      if (preOrder.productType === conf.settings.ALBUM_PACKAGING_PRODUCT_TYPE) {
        albumPackagingPreOrder = preOrder;
      }
    });

    return [albumPreOrder, albumPackagingPreOrder];
  }

  async redirectToAlbumNew() {
    navigateTo(this.request, this.response, 'album:new', {}, true);
  }

  async init(projectId) {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      let nextState = this.request.query['next-url']
        ? this.request.query['next-url']
        : 'album:main';

      let project = null;
      let preOrders = null;
      let queryParams = null;

      try {
        [project, preOrders] = await Promise.all([
          this.getProject(projectId),
          this.getPreOrders(projectId)
        ]);
        queryParams = {
          configurationId: preOrders[0].configurationId,
          packagingConfigurationId: preOrders[1].configurationId,
          professionalProjectId: project.id,
          preOrderId: preOrders[0].id,
          packagingPreOrderId: preOrders[1].id
        };
      } catch (err) {
        if (err.code === 404) {
          GuiErrors.modalInfo(
            this.i18n.gettext(
              'Il prodotto che stai cercando è stato eliminato. Puoi procedere configurando un nuovo prodotto.'
            ),
            null,
            null,
            null,
            this.redirectToAlbumNew.bind(this)
          );
          return;
        } else {
          Logger.error('AlbumRestoreProjectController.init - failed to fetch project or preorder', {
            error: err,
            projectId: projectId,
            project: project,
            preOrders: preOrders
          });
          nextState = conf.settings.HOMEPAGE_BASE_URL;
        }
      }

      if (project && preOrders) {
        try {
          await this.initClientStorage(project, preOrders);
        } catch (err) {
          Logger.error('AlbumRestoreProjectController.init - failed to initialize client storage', {
            error: err,
            projectId: projectId,
            project: project,
            preOrders: preOrders
          });
          nextState = conf.settings.HOMEPAGE_BASE_URL;
        }
      }

      navigateTo(this.request, this.response, nextState, {}, true, queryParams);
    }
  }
}

export class AlbumController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get productType() {
    return 'Album';
  }

  get funnelStep() {
    return 1;
  }

  get funnelSteps() {
    return albumFunnelSteps(this.request);
  }

  getCallToAction() {
    return this.i18n.gettext('Procedi');
  }

  getTitle() {
    return this.product && this.product.productItem && this.product.productItem.familyName
      ? this.product.productItem.familyName
      : this.i18n.gettext('Album');
  }

  get context() {
    return Object.assign(super.context, {
      product: this.product, // informazioni sul prodotto
      configuration: this.configuration, // stato dei filtri
      filters: this.filters, // collection dei filtri
      filtersViewValidity: this.filtersViewValidity,
      priceValidity: this.priceValidity,
      errorsVisibility: this.errorsVisibility,
      user: this.user,
      customerUser: this.customerUser,
      loadingCollection: this.loadingCollection,
      initViewRendering: this.initViewRendering,
      loadingPage: this.loadingPage,
      loadingPreview: this.loadingPreview,
      productConfigurationIsLoading: this.productConfigurationIsLoading,
      filtersViewIsLoading: this.filtersViewIsLoading,
      showCoverEditor: this.showCoverEditor,
      coverEditorUrl: this.coverEditorUrl,
      coverEditorProjectIsValid: this.coverEditorProjectIsValid,
      coverEditorController: this.coverEditorController,
      coverEditorNotConfigured: this.coverEditorNotConfigured,
      updatePrice: this.updatePrice,
      graphicsLoaded: this.graphicsLoaded,
      unitOfMeasure: this.unitOfMeasure
    });
  }

  get view() {
    return AlbumContentView;
  }

  async initContentProps() {
    super.initContentProps();

    // initialize content view properties
    this.product = {
      collection: new AlbumProductCollection(),
      productItem: {},
      previewUrl: null,
      productConfiguration: new AlbumConfiguration(),
      price: {},
      showWidget: false,
      showWidgetPrice: this.customerUserIsAuthorized
    };

    this.showCoverEditor = false;
    this.coverEditorUrl = null;
    this.coverEditorProjectIsValid = true;
    this.coverEditorController = null;
    this.coverEditorNotConfigured = false;
    this.graphicsLoaded = false;
  }

  async loadInitialConfiguration() {
    let configuration = {};
    let currentConfiguration;
    let configurationId = this.request.query.configurationId;
    if (!configurationId) {
      Logger.warning(
        'AlbumController.loadInitialConfiguration - missing configurationId query param'
      );
      configurationId = await AlbumStorage.getConfigurationPk();
    }

    if (configurationId) {
      Logger.info('RESTORE Album ConfigurationId', configurationId);
      currentConfiguration = new AlbumConfiguration({ id: configurationId });

      try {
        await currentConfiguration.fetch();
      } catch (err) {
        if (err.code === 404) {
          GuiErrors.modalInfo(
            this.i18n.gettext(
              'La configurazione che stai cercando è stata eliminata. Puoi procedere configurando un nuovo prodotto.'
            )
          );
          let url = ReferralStorage.getAlbumCatalogueUrl(this.getLanguage());
          this.response.navigate(url);
        } else {
          Logger.error('AlbumController.loadInitialConfiguration - AlbumConfiguration FETCH', {
            error: err,
            id: configurationId
          });
        }
        return configuration;
      }

      if (!currentConfiguration.configurationFamily) {
        let timestamp = new Date().getTime();
        Logger.error('AlbumController.loadInitialConfiguration', {
          error: 'NO configurationFamily',
          errorCode: timestamp,
          configuration: currentConfiguration
        });
        GuiErrors.modalError(
          this.i18n.gettext('Non è stato possibile caricare la configurazione.'),
          'Error detail: AlbumController loadInitialConfiguration - code: ' + timestamp,
          null,
          null,
          this.redirectToAlbumCatalogue.bind(this)
        );
        return configuration;
      }

      this.product.productConfiguration = currentConfiguration;
      Logger.info('restored configuration', currentConfiguration.toJSON());

      configuration = {
        bandType: currentConfiguration.bandType,
        bandTexts: currentConfiguration.bandTexts,
        bindingType: currentConfiguration.bindingType,
        eventType: currentConfiguration.eventType,
        configurationFamily: currentConfiguration.configurationFamily,
        coverColorMaterialType: currentConfiguration.coverColorMaterialType,
        coverEngravedType: currentConfiguration.coverEngravedType,
        coverEngravedTexts: currentConfiguration.coverEngravedTexts,
        debossingType: currentConfiguration.debossingType,
        debossingTexts: currentConfiguration.debossingTexts,
        dryDebossingType: currentConfiguration.dryDebossingType,
        flyleafColorMaterialType: currentConfiguration.flyleafColorMaterialType,
        flyleafType: currentConfiguration.flyleafType,
        formatType: currentConfiguration.formatType,
        gildingType: currentConfiguration.gildingType,
        initialType: currentConfiguration.initialType,
        initialTexts: currentConfiguration.initialTexts,
        // pagesNumber: currentConfiguration.pagesNumber,
        orientationType: currentConfiguration.orientationType,
        sheetsNumber: currentConfiguration.pagesNumber / 2,
        paperLaminationType: currentConfiguration.paperLaminationType,
        paperType: currentConfiguration.paperType,
        plateType: currentConfiguration.plateType,
        plateTexts: currentConfiguration.plateTexts,
        plaqueType: currentConfiguration.plaqueType,
        plaqueTexts: currentConfiguration.plaqueTexts,
        swarovskiType: currentConfiguration.swarovskiType,
        coverUvGraphicTexts: currentConfiguration.coverUvGraphicTexts,
        coverUvGraphicElementType: currentConfiguration.coverUvGraphicElementType,
        coverUvGraphicElementSvgName: currentConfiguration.coverUvGraphicElementSvgName,
        coverUvGraphicThemeType: currentConfiguration.coverUvGraphicThemeType,
        coverUvGraphicType: currentConfiguration.coverUvGraphicType
      };

      this.configuration = configuration;

      await this.initCoverEditorController();
    } else {
      // if configurationId is not present redirect to album catalogue
      let timestamp = new Date().getTime();
      Logger.error('AlbumController.loadInitialConfiguration', {
        error: 'NO configurationId',
        errorCode: timestamp
      });
      this.redirectToAlbumCatalogue();
      return null;
    }
  }

  async init() {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      let initialConfiguration = await this.loadInitialConfiguration();

      this.stopInitialRendering();

      if (initialConfiguration !== null) {
        await this.reloadView();
      }

      EventTracker.log(
        this.customerUser,
        'configurator_view',
        productInterface(this.product.productConfiguration)
      );
    }
  }

  async redirectToAlbumCatalogue() {
    await cleanAlbumConfigurator();
    let url = ReferralStorage.getAlbumCatalogueUrl(this.getLanguage());
    this.response.navigate(url);
  }

  /*********************************************************
   ***********  AlbumCover editor Start  *************
   *********************************************************/
  hasCoverEditorWidget() {
    return this.filters && this.filters.coverPhotoTypes && this.filters.coverPhotoTypes.length > 0;
  }

  isCoverEditorProjectInitialized() {
    return (
      this.coverEditorController &&
      this.coverEditorController.coverEditorProjectModel &&
      this.coverEditorController.coverEditorProjectModel.init
    );
  }

  isCoverEditorProjectSaved() {
    return (
      this.product.productConfiguration && this.product.productConfiguration.coverEditorProjectId
    );
  }

  isCoverEditorProjectValid() {
    return (
      this.isCoverEditorProjectSaved() &&
      !this.isCoverEditorProjectInitialized() &&
      this.coverEditorProjectIsValid
    );
  }

  async initCoverEditorController() {
    this.coverEditorController = new AlbumCoverWithPhotoEditor(
      this.request,
      this.product.productConfiguration
    );

    if (
      this.product.productConfiguration &&
      this.product.productConfiguration.coverEditorProjectId
    ) {
      this.coverEditorController.coverEditorProjectModel =
        await this.coverEditorController.getCoverEditorProject(
          this.product.productConfiguration.coverEditorProjectId
        );
    }
  }

  async resetCoverEditorProject() {
    Logger.info('RESET cover editor project');

    this.coverEditorProjectIsValid = false;

    // ricarico i widget
    await this.reloadView();

    this.productConfigurationIsLoading = true;
    this.render(this.context);

    // salvo la nuova configurazione per forzare i nuovi dati produttivi
    await this.saveProductConfiguration();

    // eseguo una fetch del progetto per invalidare il modello
    await this.coverEditorController.resetCoverEditorProject();

    this.productConfigurationIsLoading = false;
    this.render(this.context);
  }

  /*
   * Returns the AeCoverEditor application url
   */
  async showCoverEditorIframe() {
    this.startLoadingPage();

    // save configuration (now we have a valid configuration to save)
    let albumConfiguration = await this.saveProductConfiguration();
    if (!albumConfiguration) {
      this.stopLoadingPage();
      return false;
    }

    // log in user if necessary
    if (!this.isUserLoggedIn()) {
      this.redirectToLoginPage();
      return false;
    }

    // alert for the users without shopcode
    if (!this.customerUserIsAuthorized) {
      GuiErrors.modalInfo(this.i18n.gettext('Non hai permessi per aprire Editor'));
      this.stopLoadingPage();
      return false;
    }

    try {
      // create CoverEditorProject (if necessary)
      if (!this.isCoverEditorProjectSaved()) {
        // creo un progetto nuovo AeCoverEditor
        Logger.info('Create new project');
        await this.coverEditorController.createCoverEditorProject();
      }
    } catch (err) {
      this.stopLoadingPage();
      let timestamp = new Date().getTime();
      GuiErrors.modalError(
        this.i18n.gettext('Non è stato possibile aprire cover editor'),
        'Error detail: createCoverEditorProject - code: ' + timestamp
      );
      Logger.error('Album.createCoverEditorProject', {
        error: err,
        errorCode: timestamp,
        configuration: this.configuration
      });
      return false;
    }

    this.coverEditorUrl = await this.coverEditorController.getCoverEditorApplicationUrl();
    this.stopLoadingPage();

    this.showCoverEditor = this.coverEditorUrl !== null;

    this.render(this.context);
  }

  hideCoverEditorIframe() {
    this.showCoverEditor = false;
    this.render(this.context);
  }

  async saveCoverEditorProject(projectId, coverId) {
    try {
      // valido il widget per eliminare l'errore
      this.coverEditorProjectIsValid = true;
      this.loadingPreview = true;
      this.productConfigurationIsLoading = true;
      this.render(this.context);

      // save cover editor project id into eventbook configuration
      this.product.productConfiguration.coverEditorProjectId = projectId;
      this.product.productConfiguration = await this.saveProductConfiguration(false);

      GuiNotifications.notificationInfo(
        this.i18n.gettext('La tua configurazione è stata salvata con successo'),
        10000
      );

      // syncronize model with last save
      await this.coverEditorController.syncCoverEditorProject();
    } catch (err) {
      let timestamp = new Date().getTime();
      Logger.error('AlbumController.saveCoverEditorProject', {
        error: err,
        errorCode: timestamp,
        projectId: projectId,
        coverId: coverId
      });
      GuiErrors.modalError(
        this.i18n.gettext(
          'Non è stato possibile salvare la copertina fotografica, si prega di ripetere la procedura.'
        ),
        'Error detail: rasterizeCoverSvg - code: ' + timestamp
      );
    } finally {
      this.loadingPreview = false;
      this.productConfigurationIsLoading = false;
      this.render(this.context);
    }
  }

  /*********************************************************
   ***********  AlbumCover editor End  *************
   *********************************************************/

  async reloadView(getPrice = true) {
    this.productConfigurationIsLoading = true;
    this.filtersViewIsLoading = true;
    if (getPrice) {
      this.updatePrice = false;
    }
    this.render(this.context);
    try {
      this.resetFilterErrorsCollection();

      await Promise.all([
        this.loadCollection(new AlbumBindingTypesCollection()),
        this.loadCollection(new AlbumEventTypesCollection()),
        this.loadCollection(new AlbumPlateTypesCollection())
      ]);

      await Promise.all([
        this.loadCollection(new AlbumPaperTypesCollection()),
        this.loadCollection(new AlbumFlyleafTypeCollection()),
        this.loadCollection(new AlbumPlateTextCollection()),
        this.setSheetsnumberMinMax()
      ]);

      await this.loadCollection(new AlbumOrientationTypesCollection());

      await Promise.all([
        this.loadProduct(),
        this.loadCollection(new AlbumPaperLaminationTypesCollection()),
        this.loadCollection(new AlbumFormatTypesCollection())
      ]);

      await Promise.all([
        this.loadCollection(
          new AlbumCoverColorMaterialTypesCollection(),
          this.configuration.coverColorMaterialType
        )
      ]);

      await Promise.all([
        this.loadCollection(new AlbumFlyleafColorMaterialTypeCollection()),
        this.loadCollection(new AlbumPlaqueTypesCollection()),
        this.loadCollection(new AlbumInitialTypesCollection()),
        this.loadCollection(new AlbumSwarovskiCrystalTypesCollection()),
        this.loadCollection(new AlbumDebossingTypesCollection()),
        this.loadCollection(new AlbumCoverEngravedTypesCollection()),
        this.loadCollection(new AlbumGildingTypesCollection()),
        this.loadCollection(new AlbumCoverPhotoTypesCollection()),
        this.loadCollection(new AlbumUvGraphicCollection())
      ]);

      /*
       * Inizializzare la fase di rendering prima dei widget UV, altrimenti non vengono caricati gli SVG
       * */

      await Promise.all([this.loadCollection(new AlbumUvGraphicThemeCollection())]);

      await Promise.all([
        this.loadUvGraphicElementsCollection(new AlbumUvGraphicElementCollection(), {
          uvGraphicElementSvgName: 'coverUvGraphicElementSvgName',
          uvGraphicElementType: 'coverUvGraphicElementType'
        }),
        this.loadCollection(new AlbumPlaqueTextCollection()),
        this.loadCollection(new AlbumInitialsTextCollection()),
        this.loadCollection(new AlbumDebossingTextCollection()),
        this.loadCollection(new AlbumCoverEngravedTextsCollection()),
        this.loadCollection(new AlbumDryDebossingTypesCollection())
      ]);

      await Promise.all([
        this.loadCollection(new AlbumUvGraphicTextCollection()),
        this.loadCollection(new AlbumBandTypesCollection())
      ]);

      await Promise.all([this.loadCollection(new AlbumBandsTextCollection())]);
    } catch (err) {
      Logger.error('AlbumController.reloadView-loadFilters', {
        error: err,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
    } finally {
      this.filtersViewIsLoading = false;
      this.render(this.context);
    }

    this.getPriceForCurrentConfiguration(getPrice);
  }

  async getPriceForCurrentConfiguration(getPrice) {
    if (this.filters === []) {
      let timestamp = new Date().getTime();
      Logger.error('AlbumController.reloadView-emptyfilters', {
        error: 'Empty filters',
        errorCode: timestamp,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
      this.productConfigurationIsLoading = false;
      this.render(this.context);
    } else {
      try {
        this.loadProduct();
        if (getPrice) {
          this.loadPrice();
        }
      } catch (err) {
        Logger.error('AlbumController.reloadView-LoadProduct', {
          error: err,
          configuration: this.configuration,
          productConfiguration: this.product.productConfiguration,
          filters: this.filters
        });
      } finally {
        this.productConfigurationIsLoading = false;
        this.updatePrice = true;
        this.render(this.context);
      }
    }
  }

  getConfigurationRequiredParams() {
    return {
      bindingType: this.configuration.bindingType,
      configurationFamily: this.configuration.configurationFamily,
      coverColorMaterialType: this.configuration.coverColorMaterialType,
      orientationType: this.configuration.orientationType,
      formatType: this.configuration.formatType,
      paperType: this.configuration.paperType,
      plateType: this.configuration.plateType
    };
  }

  getConfigurationOptionalParams() {
    let params = {};
    if (this.filters.flyleafTypes && this.filters.flyleafTypes.length > 0) {
      params.flyleafType = this.configuration.flyleafType;
    }
    if (
      this.filters.flyleafColorMaterialTypes &&
      this.filters.flyleafColorMaterialTypes.length > 0
    ) {
      params.flyleafColorMaterialType = this.configuration.flyleafColorMaterialType;
    }
    if (this.filters.bandTypes && this.filters.bandTypes.length > 0) {
      params.bandType = this.configuration.bandType;
    }
    if (this.filters.coverEngravedTypes && this.filters.coverEngravedTypes.length > 0) {
      params.coverEngravedType = this.configuration.coverEngravedType;
    }
    if (this.filters.debossingTypes && this.filters.debossingTypes.length > 0) {
      params.debossingType = this.configuration.debossingType;
    }
    if (this.filters.dryDebossingTypes && this.filters.dryDebossingTypes.length > 0) {
      params.dryDebossingType = this.configuration.dryDebossingType;
    }
    if (this.filters.gildingTypes && this.filters.gildingTypes.length > 0) {
      params.gildingType = this.configuration.gildingType;
    }
    if (this.filters.initialTypes && this.filters.initialTypes.length > 0) {
      params.initialType = this.configuration.initialType;
    }
    if (this.filters.paperLaminationTypes && this.filters.paperLaminationTypes.length > 0) {
      params.paperLaminationType = this.configuration.paperLaminationType;
    }
    if (this.filters.plaqueTypes && this.filters.plaqueTypes.length > 0) {
      params.plaqueType = this.configuration.plaqueType;
    }
    if (this.filters.swarovskiTypes && this.filters.swarovskiTypes.length > 0) {
      params.swarovskiType = this.configuration.swarovskiType;
    }
    if (this.filters.coverUvGraphicTypes && this.filters.coverUvGraphicTypes.length > 0) {
      params.coverUvGraphicType = this.configuration.coverUvGraphicType;
    }
    if (this.filters.coverUvGraphicThemeTypes && this.filters.coverUvGraphicThemeTypes.length > 0) {
      params.coverUvGraphicThemeType = this.configuration.coverUvGraphicThemeType;
    }
    if (
      this.filters.coverUvGraphicElementTypes &&
      this.filters.coverUvGraphicElementTypes.length > 0
    ) {
      params.coverUvGraphicElementType = this.configuration.coverUvGraphicElementType;
    }

    return params;
  }

  async loadProduct() {
    let requiredParams = this.getConfigurationRequiredParams();
    let productParams = Object.assign(requiredParams, this.getConfigurationOptionalParams());
    let product = await super.getProduct(new AlbumProductCollection(), productParams);
    if (product) {
      this.setConfigurationProduct(product);
    }
  }

  async setConfigurationProduct(productItem) {
    super.setConfigurationProduct(productItem);
    this.render(this.context);
  }

  async loadPrice() {
    let requiredParams = await getConfigurationRequiredPriceParams(this.configuration);
    requiredParams = Object.assign(requiredParams, {
      pagesNumber: this.configuration.sheetsNumber * 2,
      shopCode: this.customerUserIsAuthorized ? this.customerUser.shopCode : null
    });
    let priceOptionalParams = await getConfigurationOptionalPriceParams(
      this.filters,
      this.configuration
    );
    let priceParams = Object.assign(requiredParams, priceOptionalParams);
    let price = await this.getPrice(new AlbumPriceCollection(), priceParams);
    if (price) {
      this.setConfigurationPrice(price);
    }
  }

  setSheetsnumberMinMax() {
    if (this.filters.bindingTypes) {
      this.filters.bindingTypes.each((item) => {
        if (item.id === this.configuration.bindingType) {
          this.filters.sheetsNumber = { min: item.minSpreadsNumber, max: item.maxSpreadsNumber };
        }
      });
    }
  }

  getPreparedConfiguration(configuration) {
    let shopcode = null;
    let sheets = null;
    let previewProductCode = null;
    if (this.customerUserIsAuthorized) {
      shopcode = this.customerUser.shopCode;
    }
    if (this.configuration && this.configuration.sheetsNumber) {
      sheets = this.configuration.sheetsNumber * 2;
    }
    if (this.product && this.product.productItem) {
      previewProductCode = this.product.productItem.id;
    }

    if (!configuration) {
      configuration = this.configuration;
    }
    configuration.shopCode = shopcode;
    configuration.pagesNumber = sheets;
    configuration.previewProductCode = previewProductCode;

    configuration.bandType =
      this.filters.bandTypes && this.filters.bandTypes.length > 0 ? configuration.bandType : null;
    configuration.coverEngravedType =
      this.filters.coverEngravedTypes && this.filters.coverEngravedTypes.length > 0
        ? configuration.coverEngravedType
        : null;
    configuration.debossingType =
      this.filters.debossingTypes && this.filters.debossingTypes.length > 0
        ? configuration.debossingType
        : null;
    configuration.dryDebossingType =
      this.filters.dryDebossingTypes && this.filters.dryDebossingTypes.length > 0
        ? configuration.dryDebossingType
        : null;
    configuration.flyleafType =
      this.filters.flyleafTypes && this.filters.flyleafTypes.length > 0
        ? configuration.flyleafType
        : null;
    configuration.flyleafColorMaterialType =
      this.filters.flyleafColorMaterialTypes && this.filters.flyleafColorMaterialTypes.length > 0
        ? configuration.flyleafColorMaterialType
        : null;
    configuration.initialType =
      this.filters.initialTypes && this.filters.initialTypes.length > 0
        ? configuration.initialType
        : null;
    configuration.paperLaminationType =
      this.filters.paperLaminationTypes && this.filters.paperLaminationTypes.length > 0
        ? configuration.paperLaminationType
        : null;
    configuration.plaqueType =
      this.filters.plaqueTypes && this.filters.plaqueTypes.length > 0
        ? configuration.plaqueType
        : null;
    configuration.swarovskiType =
      this.filters.swarovskiTypes && this.filters.swarovskiTypes.length > 0
        ? configuration.swarovskiType
        : null;
    configuration.gildingType =
      this.filters.gildingTypes && this.filters.gildingTypes.length > 0
        ? configuration.gildingType
        : null;
    configuration.eventType =
      this.filters.eventTypes && this.filters.eventTypes.length > 0
        ? configuration.eventType
        : null;
    configuration.coverUvGraphicType =
      this.filters.coverUvGraphicTypes && this.filters.coverUvGraphicTypes.length > 0
        ? configuration.coverUvGraphicType
        : null;

    // save cover UV data
    if (
      !configuration.coverUvGraphicType ||
      configuration.coverUvGraphicType === conf.settings.ALBUM_COVER_UV_GRAPHIC_TYPE_NO
    ) {
      configuration.coverUvGraphicThemeType = null;
      configuration.coverUvGraphicElementType = null;
      configuration.coverUvGraphicElementSvgName = null;
      configuration.coverUvGraphicTexts = null;
    }

    return configuration;
  }

  /*
   * Graphic UV left preview handling functions section
   */

  setPreviewImage(url) {
    this.product.previewUrl = url;
    this.render(this.context);
  }

  fixSvg(svg, texts, color = undefined) {
    this.fixSvgTexts(svg, texts);
    if (color) {
      this.fixSvgColor(svg, color);
    }

    /* save the svg xml element as ready to use text */
    let s = new XMLSerializer();
    return s.serializeToString(svg);
  }

  fixSvgColor(svg, color) {
    /*fix the svg color for all svg elements*/
    let allElements = svg.querySelectorAll('*');
    for (let i = 0; i < allElements.length; i++) {
      allElements[i].setAttribute('fill', color);
    }
  }

  fixSvgTexts(svg, texts) {
    if (
      this.isSvgTextTypedByUser() ||
      (this.getPreviewTexts() && Object.keys(this.getPreviewTexts()).length > 0)
    ) {
      let svgTextElements = svg.querySelectorAll('text');

      for (let i = 0; i <= svgTextElements.length; i++) {
        let svgTextElement = svgTextElements[i];

        if (svgTextElement) {
          let svgTextElementId = svgTextElement.getAttribute('id');

          if (svgTextElementId) {
            texts = this.getPreviewTexts()[svgTextElementId];
            let text = '';
            if (texts) {
              text = texts;
            }
            svgTextElement.childNodes[0].nodeValue = text;
          } else {
            console.error('SVG have text component without id');
          }
        }
      }
    }
  }

  async setPreviewSvg(svgUrl, texts) {
    svgUrl = svgUrl || this.product.previewSvgUrl;
    if (svgUrl) {
      var request = axios;
      request
        .get(svgUrl)
        .then((response) => {
          var data = response.data;
          var doc = new DOMParser().parseFromString(data, 'text/html');
          let svg = doc.getElementsByTagName('svg')[0];
          this.product.previewSvg = this.fixSvg(svg, texts);
          this.render(this.context);
        })
        .catch(function (error) {
          Logger.error('setPreviewSvg - Error on SVG file retrieve', {
            url: svgUrl,
            error: error
          });
        });
    }
  }

  clearPreviewSvg() {
    this.product.previewSvgUrl = null;
    this.product.previewSvg = null;
  }

  chosePreviewSvg(svgUrl) {
    this.product.previewSvgUrl = svgUrl;
  }

  setPreviewSvgColor(color) {
    if (color) {
      this.product.svgColor = color;
    } else {
      this.product.svgColor = this.product.chosenSvgColor;
    }
  }

  chosePreviewSvgColor(color) {
    color = color || this.product.svgColor;
    this.product.chosenSvgColor = this.product.svgColor = color;
  }

  chosePreviewTexLimit(rowLimitMin, rowLimitMax) {
    this.product.previewTexLimitMin = rowLimitMin;
    this.product.previewTexLimitMax = rowLimitMax;
  }

  chosePreviewSvgText() {
    this.product.SvgTextTyped = true;
  }

  isSvgTextTypedByUser() {
    return this.product.SvgTextTyped;
  }

  /** END OF left preview handling functions section **/

  getPreviewTexts() {
    return this.configuration.coverUvGraphicTexts;
  }

  async saveConfiguration(currentConfiguration) {
    return await this.product.productConfiguration.save(currentConfiguration); //eslint-disable-line
  }

  async saveProductConfigurationWithRetry(currentConfiguration) {
    return await promiseRetry(
      this.saveConfiguration.bind(this, currentConfiguration),
      'Save product configurations',
      { currentConfiguration: currentConfiguration }
    );
  }

  async saveProductConfiguration(saveLocal = true) {
    try {
      let currentConfiguration = this.getPreparedConfiguration();
      this.coverEditorNotConfigured = false;
      let albumConfiguration = await this.saveProductConfigurationWithRetry(currentConfiguration);
      Logger.info('Album Configuration id:', this.product.productConfiguration.id);

      if (saveLocal) {
        await AlbumStorage.setConfigurationPk(this.product.productConfiguration.id);
      }
      return albumConfiguration;
    } catch (err) {
      let timestamp = new Date().getTime();
      Logger.error('AlbumController.saveProductConfiguration', {
        error: err,
        errorCode: timestamp,
        configuration: this.product.productConfiguration
      });
      GuiErrors.modalError(
        this.i18n.gettext(
          'Non è stato possibile salvare la configurazione, si prega di ripetere la procedura.'
        ),
        'Error detail: saveProductConfiguration - code: ' + timestamp
      );
      return undefined;
    }
  }

  async validateWidgetErrors() {
    /*
     * Validate cover editor errors
     */
    if (this.hasCoverEditorWidget() && !this.product.productConfiguration.coverEditorProjectId) {
      await this.setErrorsVisibility(true);
      this.coverEditorNotConfigured = true;
      this.stopLoadingPage();
      this.viewInstance.scrollToFirstWidgetError();
      return false;
    }
  }

  async goToNextStep() {
    this.errorsVisibility = true;
    this.startLoadingPage();
    await this.validateWidgetErrors();

    if (await this.checkWidgetErrors()) {
      let albumConfiguration = await this.saveProductConfiguration();
      if (albumConfiguration) {
        // this.response.navigate('album-packaging:main');
        let queryParams = {
          configurationId: albumConfiguration.id,
          packagingConfigurationId: albumConfiguration.packagingConfigurationId
        };
        navigateTo(
          this.request,
          this.response,
          'album-packaging:main',
          {},
          false,
          mergeQueryParams(this.request.query, queryParams)
        );
      }
    }
    this.stopLoadingPage();
  }

  async goToCatalogue() {
    this.startLoadingPage();

    if (await this.checkWidgetErrors()) {
      await this.saveProductConfiguration();
    }

    let backToCatalogueUrl = ReferralStorage.getAlbumCatalogueUrl(this.getLanguage());
    this.response.navigate(
      backToCatalogueUrl + '?configurationId=' + this.product.productConfiguration.id
    );
  }

  getdisabledWidgets() {
    let disabledList = [];
    let code = this.product.productConfiguration.serviceConfigurationTypeCode;
    if (code === LayoutServicesCodes.aeVeloceCode) {
      disabledList = ['sheetsNumber', 'orientationType'];
    } else if (code === LayoutServicesCodes.readyToPrintCode) {
      disabledList = ['orientationType'];
    } else if (code === LayoutServicesCodes.fullServiceDesignCode) {
      disabledList = ['sheetsNumber', 'orientationType'];
    }
    return disabledList;
  }
}
